import { useRouter } from 'next/router';
import { BASE_ROUTES, QUIZ_ROUTES } from 'core/common/constants';
import { joinUrls } from 'core/common/utils/joinUrls';

export const useHome = () => {
  const router = useRouter();

  const startQuiz = () => {
    router.push(joinUrls(BASE_ROUTES.APP_SUBSCRIPTION_4_7D_TRIAL_PER_DAY, QUIZ_ROUTES.GENDER));
  };

  return {
    startQuiz,
  };
};
