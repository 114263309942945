import styled from 'styled-components';

export const PageBackgroundStyled = styled.div`
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: ${({ theme }) => theme.colors.themeBackgroundLightSecondary};
    content: '';
  }
`;

export const ContentWrapperStyled = styled.div`
  position: relative;
  z-index: 1;
`;

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 360px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 800px;
    background: linear-gradient(180deg, #fff0f0 0%, #fffbfb 34.9%, #fffbfb 77.6%, #fff0f0 100%);
  }
`;
